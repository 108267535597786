import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { isBrowser } from '../utils';

const LoginButton = () => {
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();
  const handleClick = () => {
    if (isAuthenticated) {
      logout({ returnTo: window.location.origin });
    } else {
      if (isBrowser()) {
        loginWithRedirect();
      }
    }
  };
  return (
    <button className="link" onClick={handleClick}>
      {isAuthenticated ? 'Logout' : 'Login'}
    </button>
  );
};

export default LoginButton;
