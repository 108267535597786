import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import LoginButton from "./LoginButton";
import { useAuth0 } from "@auth0/auth0-react";

const renderMenuLinks = (links) =>
  links.map((link) => (
    <Link
      key={link.link}
      className="link"
      activeClassName="active"
      to={link.link}
    >
      {link.name}
    </Link>
  ));

const Navbar = () => {
  const {
    site: {
      siteMetadata: { menuLinks, authorizedLinks },
    },
  } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          menuLinks {
            link
            name
          }
          authorizedLinks {
            link
            name
          }
        }
      }
    }
  `);

  const { isAuthenticated } = useAuth0();

  return (
    <nav>
      <div className="nav-container">
        <div className="navbar-wrapper">
          <Link to="/" className="name">
            Portfolio.
          </Link>
          <div className="links-wrapper">
            {renderMenuLinks(menuLinks)}
            {isAuthenticated ? renderMenuLinks(authorizedLinks) : null}
            <LoginButton />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
