/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import Navbar from "../components/Navbar";
import { Auth0Provider } from "@auth0/auth0-react";
import { navigate } from "gatsby";

import "../styles/mains.scss";

const Layout = ({ children }) => {
  const onRedirectCallback = (appState) => {
    console.log({ appState });
    navigate(appState?.returnTo ?? "/", { replace: true });
  };
  return (
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN}
      clientId={process.env.AUTH0_CLIENTID}
      redirectUri={process.env.AUTH0_CALLBACK}
      audience="https://auth0-jwt-authorizer"
      scope="openid profile email write:text"
      onRedirectCallback={onRedirectCallback}
    >
      <Navbar />
      <main>{children}</main>
    </Auth0Provider>
  );
};
export default Layout;
